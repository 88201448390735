@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .animate-hover {
    @apply hover:scale-105 transition duration-150;
  }
}

body {
  color: #fff;
  font-family: "Be Vietnam Pro", "sans-serif";
  background-color: #000;
}

.div-button {
  transition: all 0.3s ease-in-out;

  &:hover {
    cursor: pointer;

    transform: scale(1.05);
    opacity: 0.8;
  }

  &:active {
    transform: scale(0.95);
    opacity: 0.6;
  }
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background: #000;
}
::-webkit-scrollbar-thumb:horizontal {
  background: #000;
  border-radius: 10px;
}

.show {
  display: flex;
}
